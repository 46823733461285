module services {
    export module customs {
        export class accountDetailService implements interfaces.customs.IAccountDetailService {
            static $inject = ["$resource", "ENV", "$http", "$timeout", "generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, private $timeout: ng.ITimeoutService, public generalService: interfaces.applicationcore.IGeneralService) {
            }

            getList(): ng.resource.IResourceClass<interfaces.customs.IAccountLineDisplay> {
                return this.$resource<interfaces.customs.IAccountLineDisplay>(this.ENV.DSP_URL + "AccountDetail/GetList", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber',
                }, {
                    query: {
                        method: 'GET',
                        isArray: true,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        params: {
                            numberRecords: 25,
                            pageNumber: 1,
                        }
                    }
                });
            }

            getAccountLineDetail(accountLineId: number, accountPeriodId: number, accountId: number): ng.resource.IResourceClass<interfaces.customs.IAccountLine> {
                return this.$resource<interfaces.customs.IAccountLine>(this.ENV.DSP_URL + "AccountDetail/GetAccountLineDetail", {
                    accountLineId: accountLineId,
                    accountPeriodId: accountPeriodId,
                    accountId: accountId,
                }, {
                    query: {
                        method: 'GET',
                        isArray: false,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                });
            }


            getAccountLines(): ng.resource.IResourceClass<interfaces.customs.IAccountLineDisplay> {
                var viewDetail: ng.resource.IActionDescriptor = {
                    method: 'GET',
                    isArray: true
                }
                return this.$resource<interfaces.customs.IAccountLineDisplay>(this.ENV.DSP_URL + "AccountDetail/GetAccountLines", {
                    accountId: "@accountId",
                    periodId: "@periodId",
                }, {
                    query: {
                        method: 'GET',
                        isArray: true,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                    }
                });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "AccountDetail/Save");
            }

        }
    }

    angular.module("app").service("accountDetailService", services.customs.accountDetailService);
}